<template>
  <div class="module-info">
    <div class="module-info__container">
      <div class="module-info__category">
        <span class="module-info__title">
          {{ $t('dashboard.info.definition') }}
        </span>
        <i18n-t
          :keypath="content.definition"
          tag="p"
          class="module-info__text"
        >
          <template
            v-if="content.boldDefinition"
            #boldDefinition
          >
            <b class="text--bold">{{ $t(content.boldDefinition) }}</b>
          </template>
        </i18n-t>
      </div>
    </div>

    <div
      v-if="content.calculation"
      class="module-info__container"
    >
      <div class="module-info__category">
        <div class="module-info__row">
          <div class="module-info__column">
            <span class="module-info__title">
              {{ $t('dashboard.info.calculation') }}
            </span>
            <p
              v-for="(legend, index) in content.calculation.legends"
              :key="index"
              class="module-info__text"
            >
              <span>{{ $t(legend) }}</span>
            </p>
          </div>

          <!-- eslint-disable vue/no-v-html -->
          <span
            class="module-info__formula"
            v-html="renderFormula(content.calculation.formula)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="content.insight"
      class="module-info__container"
    >
      <div class="module-info__category">
        <span class="module-info__title">
          {{ $t('dashboard.info.insight') }}
        </span>
        <p class="module-info__text">
          {{ $t(content.insight) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import katex from 'katex'
import { type ModuleInfoContent } from '~/types/analytics'

export default {
  name: 'ModuleInfo',
  props: {
    content: {
      type: Object as PropType<ModuleInfoContent>,
      default: () => ({
        definition: '',
        source: '',
        calculation: {
          legends: [],
          formula: ''
        },
        insight: ''
      })
    }
  },
  methods: {
    renderFormula(formula: string) {
      // eslint-disable-next-line import/no-named-as-default-member
      return katex.renderToString(formula, { output: 'mathml' })
    }
  }
}
</script>

<style lang="scss" scoped>
.module-info {
  max-width: 480px;
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $margin-regular;

    &:last-of-type {
      margin-bottom: $margin-mini;
    }
  }

  &__category {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      margin-left: $margin-regular;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include font-text($font-weight-medium);
    @include font-size($font-size-compact);
    color: $text-primary;
    margin-bottom: 4px;
  }

  &__text {
    @include font-text;
    @include font-size($font-size-mini);
    color: $text-primary;
    white-space: pre-line;

    .text {
      &--bold {
        @include font-text($font-weight-bold);
      }
    }
  }

  &__formula {
    min-height: 24px;
    @include font-size(21px, 0);
    color: $text-primary;
    margin: 4px 0 0 $margin-small;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $margin-regular $margin-small $margin-small;
    border: solid 1px $text-primary;
    border-radius: 4px;
    transition: opacity $short-transition ease-in;
  }
}
</style>
